<script setup lang="ts">
const { t } = useI18n()
const link = (useAttrs().link as string) ?? "/"
const attrs = useAttrs()
const linkText = computed(() => {
  return (attrs?.["link-text"] as string) ?? t("navigation.exit")
})
</script>
<template>
  <div>
    <nav class="bg-slate-900">
      <div
        class="mx-auto flex max-w-7xl flex-row items-center justify-between px-4 py-2 sm:px-6 lg:px-8 lg:py-2.5"
      >
        <div
          class="relative flex flex-shrink-0 flex-row items-center justify-center"
        >
          <NuxtLink to="/">
            <LogoSvg class="h-8" />
          </NuxtLink>
        </div>
        <div class="ml-10 flex items-baseline space-x-4">
          <FormButton variant="dark" :to="link" data-testid="exit_button">
            {{ linkText }}
            <SvgoLogout class="ml-2 size-5" />
          </FormButton>
        </div>
      </div>
    </nav>
    <slot />
    <FlashMessage />
  </div>
</template>
